/* PrintableSubjects.css */

.printable-subjects-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .print-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .subjects-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .subject-item {
    font-size: 14px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  @media print {
    .print-button {
      display: none;
    }
    .subject-item {
      page-break-inside: avoid;
    }
  }