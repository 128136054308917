@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Noto+Serif+TC:wght@200;400;500&display=swap');

body{
  font-family: "Noto Sans CJK TC", "Microsoft JhengHei", "PingFang", "STHeiti", "sans-serif", "serif";
  /* <!-- 這行是上面的翻譯⇒font-family: 思源黑體, 微軟正黑體, 蘋方體, 黑體, sans-serif(無襯線字), serif(襯線字); --> */
}

.language-switcher {
  text-align: right;
  position: absolute;
  top: 16px;
  right: 16px;
}

.welcome-title {
  font-family: 'Merriweather', serif;
  font-size: 3rem;
  font-weight: bold;
  color: #03487d;
  text-shadow: 5px 5px 7px rgba(255, 255, 143, 0.954);
  margin-bottom: 16px;
}

.section-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #163045; /* Steel Blue */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #87cefa; /* Light Sky Blue */
  padding-bottom: 8px;
  margin-bottom: 16px;
  display: inline-block;
}

.total-idioms-count {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
}

body {
  background-color: #fffffc74; /* Alice Blue */
}

.container {
  background-color: #a9e7f8; /* Light Blue */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.MuiFormControlLabel-label {
  color: #1e90ff; /* Dodger Blue */
}

.alert {
  background-color: #e0f7fa; /* Light Cyan */
  color: #00796b; /* Teal */
}

.footer {
  background-color: #87cefa; /* Light Sky Blue */
  color: #fff;
  padding: 10px;
  text-align: center;
}

.chyron-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  margin: 20px 0; /* Add margin for spacing */
}

.chyron {
  justify-content: center; /* Center horizontally */
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  margin: 20px 0;
}

.chyron-content {
  /* display: inline-block; */
  animation: scroll 60s linear infinite;
}

.chyron-item {
  display: inline-block;
  padding: 0 20px;
  font-size: 1rem;
  color: #00796b; /* Teal */
}

.chyron-content:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}