.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.search-bar {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px 10px 0 0;  /* Rounded corners only at the top */
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .1);
  border: 1px solid #ccc;
  border-bottom: none;  /* Remove bottom border */
  margin-bottom: 0;  /* Remove bottom margin */
}

.search-input {
  margin-left: 1px;
  flex: 1;
}

.icon-button {
  padding: 10px;
}

.search-type-radio {
  width: 100%;
  border-top: none;  /* Remove top border */
  border-radius: 0 0 10px 10px;  /* Rounded corners only at the bottom */
  padding: 5px 0;
  display: flex;
  justify-content: center;
}

/* Style for radio buttons to make them more compact */
.search-type-radio .MuiFormControlLabel-root {
  margin: 0 10px;
}