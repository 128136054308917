/* TopSubjects.css */
.top-subjects-container {
    padding: 20px;
  }
  
  .subject-card {
    transition: transform 0.2s;
    /* height: 80px; */
  }
  
  .subject-card:hover {
    transform: scale(1.05);
  }
  
  .pagination-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }