.footer-link {
    color: #333; /* Dark grey */
    text-decoration: none; /* Remove underline */
    margin-right: 16px; /* Space between links */
    padding-left: 20px;
    text-decoration-color: #333;
  }
  
  .footer-link:hover {
    text-decoration: underline; /* Optional: underline on hover */
  }