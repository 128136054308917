.highlighted-idiom {
    background-color: #ffff00;
    font-weight: bold;
  }

  .source-note {
    font-size: 0.8em;
    color: #999;
    font-style: italic;
    margin-left: 5px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  /* Optional: Make the note more visible on hover */
  .source-note:hover {
    opacity: 1;
  }

  .now-playing-label {
    font-size: 0.8em; /* Smaller font size */
    color: #999; /* Lighter color */
    font-weight: normal; /* Less bold */
    margin-right: 5px; /* Space between label and idiom */
  }

  .intro-heading {
    font-family: 'Merriweather', serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #03538f;
    text-shadow: 5px 5px 7px rgba(255, 255, 143, 0.954);
    margin-bottom: 16px;
  }