.subjects-table-container {
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subjects-table-container table {
  border-collapse: collapse;
  width: 100%;
}

.subjects-table-container th,
.subjects-table-container td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  white-space: normal;
}

.table-header {
  font-weight: bold;
  color: #4A4A4A;
  background-color: #f5f5f5;
  text-align: left;
  line-height: 1.2;
  padding: 8px 6px;
}

.table-cell {
  vertical-align: top;
}

.similar-phrase {
  font-size: 0.9rem;
  color: #555;
}

.highlighted-subject {
  background-color: #ffff00;
  font-weight: bold;
}

.subjects-table-container tr:hover {
  background-color: #f8f8f8;
}

.subject-link {
  text-decoration: none;
  color: #1976d2;
  font-weight: bold;
}

.subject-link:hover {
  text-decoration: underline;
}

.source-note {
  font-size: 0.8em;
  color: #999;
  font-style: italic;
  margin-left: 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .subjects-table-container th,
  .subjects-table-container td {
    padding: 6px;
  }
}